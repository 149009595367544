/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// import "semantic-ui-css/semantic.min.css"
// import "./src/style/main.scss"

const addScript = url => {
  const script = document.createElement("script")
  script.src = url
  script.async = true
  document.body.appendChild(script)
}

require("./src/style/main.scss")

exports.onClientEntry = () => {
  window.addEventListener("load", () => {
    addScript("https://assets.calendly.com/assets/external/widget.js")
    document.body.className = document.body.className.replace(/\bno-js\b/, "")
  })
}

exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  window.scrollTo(0, 0)

  return false
}
